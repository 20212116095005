import ImageGallery from "react-image-gallery";

const WaterLeakageOldBuilding = () => {


    const images = [
        {
          original: "https://picsum.photos/id/1018/1000/600/",
          thumbnail: "https://picsum.photos/id/1018/250/150/",
        },
        {
          original: "https://picsum.photos/id/1015/1000/600/",
          thumbnail: "https://picsum.photos/id/1015/250/150/",
        },
        {
          original: "https://picsum.photos/id/1019/1000/600/",
          thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
        {
            original: "https://picsum.photos/id/1020/1000/600/",
            thumbnail: "https://picsum.photos/id/1020/250/150/",
        },
        {
            original: "https://picsum.photos/id/1021/1000/600/",
            thumbnail: "https://picsum.photos/id/1021/250/150/",
        },
        {
            original: "https://picsum.photos/id/1022/1000/600/",
            thumbnail: "https://picsum.photos/id/1022/250/150/",
        },
        {
            original: "https://picsum.photos/id/1023/1000/600/",
            thumbnail: "https://picsum.photos/id/1023/250/150/",
        },
        {
            original: "https://picsum.photos/id/1024/1000/600/",
            thumbnail: "https://picsum.photos/id/1024/250/150/",
        },
        {
            original: "https://picsum.photos/id/1025/1000/600/",
            thumbnail: "https://picsum.photos/id/1025/250/150/",
        },
        {
            original: "https://picsum.photos/id/1026/1000/600/",
            thumbnail: "https://picsum.photos/id/1026/250/150/",
        },
      ];
      
    return(
        <div className="page-content">
             <section id="page-header" className="d-flex align-items-center">
                <div className="container">
                    <div className="page-title">
                        <h1 className="page-heading text-white">
                           Water Proofing <span className="color-primary">(Underconstruction Building)</span>
                        </h1>
                    </div>
                </div>
            </section>

            {/* <section id="services">
                <div className="container">
                    <div className="service-image">
                        <img src="/assets/images/services/1.jpg" alt="" />
                    </div>
                    <div className="service-heading">
                        <h1 className="primary-heading">water Leakage Old Building</h1>
                    </div>
                    <div className="srvice-content">
                        <p className="text">Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop.Credibly innovate granular internal or “organic” sources whereas high standards in web-readiness. Energistically scale future-proof core competencies vis-a-vis impactful experiences. Dramatically synthesize integrated schemas with optimal networks.</p>
                        <div className="service-highlight">
                        Phosfluorescently engage worldwide methodologies with web-enabled technology. Interactively coordinate proactive e-commerce via process-centric “outside the box” thinking. Credibly innovate granular internal or “organic” sources whereas high standards in web-readiness. Energistically scale future-proof core competencies vis-a-vis impactful experiences. Dramatically synthesize integrated schemas with optimal networks
                        </div>
                        <p className="text">Credibly innovate granular internal or “organic” sources whereas high standards in web-readiness. Energistically scale future-proof core competencies vis-a-vis impactful experiences. Dramatically synthesize integrated schemas with optimal networks. Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop</p>
                    </div>

                    <div className="service-heading" style={{marginTop: '30px'}}>
                        <h1 className="primary-heading">Our <span className="color-primary">Gallery</span></h1>
                    </div>
                    <ImageGallery originalHeight={200} items={images} />
                    

                </div>
            </section> */}

<section id="solution">
                <div className="container">
                    <div className="sectin-heading-group">
                        <h1 className="primary-heading">Our <span className="color-primary">Solutions</span></h1>
                    </div>
                    <div className="row solution gx-5 gy-5">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution1.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Roof Waterproofing</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution2.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Bathroom Waterproofing</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Internal Walls Waterproofg</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">External Waterproofing.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Water tanks Waterproofing</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WaterLeakageOldBuilding;