import ImageGallery from "react-image-gallery";

const Repair = () => {


 
      
    return(
        <div className="page-content">
             <section id="page-header" className="d-flex align-items-center">
                <div className="container">
                    <div className="page-title">
                        <h1 className="page-heading text-white">
                        Repair of the  <span className="color-primary">Old Building</span>
                        </h1>
                    </div>
                </div>
            </section>


<section id="solution">
                <div className="container">
                    <div className="sectin-heading-group">
                        <h1 className="primary-heading">Our <span className="color-primary">Solutions</span></h1>
                    </div>
                    <div className="row solution gx-5 gy-5">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution1.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Internal Plaster Cracks.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution2.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">External Plaster Cracks.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Structural Cracks in Concrete.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Water bearing Cracks.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Non-Structural cracks in concrete.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Honeycombs</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Pin Holes/Blow Holes</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Spalling of Cracks.</h2> 
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Entire Building Repair.</h2> 
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="/assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Protective Coatings.</h2> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Repair;