
import { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { verifyRegister } from "../../http";


const RegisterVerify = () => {

    const [error, setError] = useState({field: null, message: null});
    const [submitting, setSubmitting] = useState(false);

    const [otp, setOtp] = useState('')

    const navigate = useNavigate();
    

    const handleSubmit = async () => {

        setError({field: null, message: null});

        if(!otp) return setError({field: 'otp', message: "OTP is required"});
        if(otp.length < 4) return setError({field: 'otp', message: "Invalid OTP"});

        setSubmitting(true);

        try {
            const {data} = await verifyRegister({
                hash: sessionStorage.getItem('hash'),
                otp: otp,
                name: sessionStorage.getItem('name'),
                email: sessionStorage.getItem('email'),
                mobile: sessionStorage.getItem('mobile')
            })

            window.location = '/'

            //console.log(data);
        } catch (error) {
           setError({field: 'otp', message: error.response.data.message})
        }finally{
            setSubmitting(false)
        }

       
    }


    return (
        <div>
            <div className="auth-wrapper">
            <div className="hero-form">
                                <h2 className="accent-heading">Enter <span className="color-primary">OTP</span></h2>
                                <p className="text" style={{margin:'-20px 0 10px 0'}}><strong>We hve sent you a otp on {sessionStorage.getItem('email').substring(0,4)}***</strong></p>
                                <div className="form">
                                    <div className="mb-3 otp-wrapper">
                                       <OtpInput value={otp} onChange={setOtp} numInputs={4} renderSeparator={<span>-</span>} renderInput={(props) => <input {...props} className="form-control input" placeholder="*" />} />
                                       {error.field == 'otp' && <p className="status error" style={{textAlign: 'center',marginTop: '8px'}}>{error.message}</p>}
                                    </div>
                                    
                                    
                                    <div className="d-grid">
                                    <button onClick={handleSubmit} type="button" class="btn btn-primary-main">
                                        <span>Verify</span>
                                        {submitting ? <span class="spinner-border spinner-border-sm ml-4" style={{marginLeft: '8px'}} role="status" aria-hidden="true"></span> : (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                           <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                        </svg>)}
                                    </button>
                                    {error.field == 'form' && <p className="status error">{error.message}</p>}
                                    {/* <p className="status success">{success}</p> */}

                                    </div>
                                    
                                    
                               
                              
                                
                                </div>
                            </div>
            </div>
        </div>
    )
}

export default RegisterVerify;