import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import {useParams} from 'react-router-dom';
import { useState, useEffect } from "react";
import { getBookingById } from "../http";
import dayjs from 'dayjs'

const ThankYou = () => {

    const auth = useContext(AuthContext);

    const [booking, setBooking] = useState({})
    const {id} = useParams();

    useEffect(() => {
        const fetchBooking = async () => {
            try {
                const {data} = await getBookingById(id);
                setBooking(data.booking)
            } catch (error) {
                console.log(error)
            }
        }
        fetchBooking()
    }, [id])
    return (
        <div className="page-contect">
             <section id="page-header" className="d-flex align-items-center">
          <div className="container">
              <div className="page-title">
                  <h1 className="page-heading text-white">
                      Thank You
                  </h1>
              </div>
          </div>
      </section>
      <section>
        <div className="container">
            <div className="sec-heading">
                <p className="text color-primary">Hello {auth.name}</p>
                <h1 className="primary-heading">Thank you for your booking <br/> your booking detail is as below</h1>
            </div>
            <div className="" style={{maxWidth:'800px', width: '100%', margin: '0 auto'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e3e3e3', borderTop: '1px solid #e3e3e3', padding: '8px 4px'}}>
                    <p className="text"><strong>Booking Type : </strong></p>
                    <p className="text">{booking.booking_type}</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e3e3e3', padding: '8px 4px'}}>
                    <p className="text"><strong>Amount Pain : </strong></p>
                    <p className="text">{booking.amount_paid ? booking.amount_paid : 'Not Applicable'}</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e3e3e3', padding: '8px 4px'}}>
                    <p className="text"><strong>Transection Id : </strong></p>
                    <p className="text">{booking.transection_id ? booking.transection_id : 'Not Applicable'}</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e3e3e3', padding: '8px 4px'}}>
                    <p className="text"><strong>Service : </strong></p>
                    <p className="text">{booking.service}</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e3e3e3', padding: '8px 4px'}}>
                    <p className="text"><strong>Location : </strong></p>
                    <p className="text">{booking.location}</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e3e3e3', padding: '8px 4px'}}>
                    <p className="text"><strong>Date : </strong></p>
                    <p className="text">{dayjs(booking.createdAt).format('D MMMM, YYYY')}</p>
                </div>
            </div>
        </div>
      </section>
        </div>
    )
}

export default ThankYou;