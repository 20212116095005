import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { getPost } from '../http';

const Post = () => {

    const {slug} = useParams();
    const [post, setPost] = useState({});

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const {data} = await getPost(slug);
                setPost(data.post)
            } catch (error) {
                console.log(error)
            }
        }
        fetchPost()
    }, [slug])


   
    return(
        <div className="page-content">
             <section id="page-header" className="d-flex align-items-center">
                <div className="container">
                    <div className="page-title">
                        <h1 className="page-heading text-white">
                           {post.title}
                        </h1>
                    </div>
                </div>
            </section>

            <section id="services">
                <div className="container">
                    <div className="service-image">
                        <img src={post.image} alt="" />
                    </div>
                    <div className="service-heading">
                        <h1 className="primary-heading">{post.title}</h1>
                    </div>
                    <div className="srvice-content post-content" dangerouslySetInnerHTML={{ __html: post.content }}>
                       
                    </div>

                    

                </div>
            </section>
        </div>
    )
}

export default Post;