
import './App.css';

import Layout from './components/Layout';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Posts from './pages/Posts';
import Post from './pages/Post';

//services
import WaterLeakageOldBuilding from './pages/services/WaterLeakageOldBuilding';
import WaterProofingOld from './pages/services/WaterproofingOld';
import Repair from './pages/services/Repair';
import Tiling from './pages/services/Tiling';

import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import LogVerify from './pages/auth/LogVerify';
import RegisterVerify from './pages/auth/RegisterVerify';


import Terms from './pages/polocies/Terms';
import Privacy from './pages/polocies/Privacy';
import Refund from './pages/polocies/Refund';
import ThankYou from './pages/Thankyou';

import Booknow from './pages/Booknow';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AuthContext, AuthDispatchContext } from './context/AuthContext';
import { authRedicer } from './reducers/authReducers';
import { useReducer, useState, useEffect } from 'react';
import { checkAuth } from './http';
import MyBooking from './pages/MyBooking';
import Veryfypayments from './pages/Veryfypayments';

const router = createBrowserRouter([{
  path: '/',
  element: <Layout />,
  children: [
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/about-us',
      element: <AboutUs />
    },
    {
      path: '/contact-us',
      element: <ContactUs />
    },
    {
      path: '/book-now',
      element: <Booknow />
    },
    {
      path: '/services/waterproofing-underconstruction-building',
      element: <WaterLeakageOldBuilding />
    },
    {
      path: '/services/waterproofing-old-building',
      element: <WaterProofingOld />
    },{
     path: 'services/repair-of-the-old-building',
     element: <Repair />
    },
    {
      path: '/services/tiling-and-epoxy-flooring-solutions',
      element: <Tiling />
    },
    {
      path: '/posts',
      element: <Posts />
    },
    {
      path: '/posts/:slug',
      element: <Post />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/register',
      element: <Register />
    },
    {
      path: '/login-verify',
      element: <LogVerify />
    },
    {
      path: '/verify-registration',
      element: <RegisterVerify />
    },
    {
      path: '/my-booking',
      element: <MyBooking />
    },
    {
      path: '/terms-and-conditions',
      element: <Terms />
    },
    {
      path: '/privacy-policy',
      element: <Privacy />
    },
    {
      path: '/refund-policy',
      element: <Refund />,
    },
    {
      path: '/booknow/payment/verify/:tid',
      element: <Veryfypayments />
    },
    {
      path: '/booking/success/:id',
      element: <ThankYou />
    }
  ]
}])

function App() {

  const [auth, dispatch] = useReducer(authRedicer, {name: '', email: '', mobile: ''});

  useEffect(() => {
    const chechAuthh = async () => {
      try {
        const {data} = await checkAuth();
        if(data.auth == false){
          dispatch({type: 'notauthenticated'})
        }else{
          dispatch({type: 'login', user: data.user});
        }
      } catch (error) {
        console.log(error)
        dispatch({type: 'notauthenticated'})
      }
    }
    chechAuthh();
  }, [])


  return (
    <div className="App">
     <AuthContext.Provider value={auth}>
     <AuthDispatchContext.Provider value={dispatch}>
       <RouterProvider router={router} />
     </AuthDispatchContext.Provider>
     </AuthContext.Provider>
    </div>
  );
}

export default App;
