
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { validateEmail } from "../../tools/validator";
import { useNavigate } from "react-router-dom";
import { loginOtp } from "../../http";


const Login = () => {

    const [error, setError] = useState({field: null, message: null});
    const [submitting, setSubmitting] = useState(false);

    const [email, setEmail] = useState();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setError({field: null, message: null});
        if(!email) return setError({field: 'email', message: "Email is required"});
        if(!validateEmail(email)) return setError({field: 'email', message: 'Invalid email'});

        setSubmitting(true)

        try {
            const {data} = await loginOtp({email});

            if(data.hash){
                sessionStorage.setItem('hash', data.hash);
                sessionStorage.setItem('otp', data.otp);
                sessionStorage.setItem('email', data.email)
                navigate('/login-verify')
            }
        } catch (error) {
            console.log(error)
            setError({field: 'email', message: error.response.data.message})
        }finally{
            setSubmitting(false)
        }
    }


    return (
        <div>
            <div className="auth-wrapper">
            <div className="hero-form">
                                <h2 className="accent-heading">Enter your <span className="color-primary">Email</span> below</h2>
                                <div className="form">
                                    <div className="mb-3">
                                       <input type="email" className="form-control input" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                                       {error.field == 'email' && <p className="status error">{error.message}</p>}
                                    </div>
                                    
                                    
                                    <div className="d-grid">
                                    <button onClick={handleSubmit} type="button" class="btn btn-primary-main">
                                        <span>Get OTP</span>
                                        {submitting ? <span class="spinner-border spinner-border-sm ml-4" style={{marginLeft: '8px'}} role="status" aria-hidden="true"></span> : (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                           <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                        </svg>)}
                                    </button>
                                    {error.field == 'form' && <p className="status error">{error.message}</p>}
                                    {/* <p className="status success">{success}</p> */}

                                    </div>
                                    
                                    {/* <p className="text" style={{margin:'10px 0 10px 0', textAlign: 'center'}}><strong>Don't have an account?</strong></p> */}
                               
                                <NavLink style={{display:'grid', width: '100%', marginTop: '10px'}} to='/register'><button className="btn btn-primary" type="submit">
        <span>Register Now</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
</svg>

    </button></NavLink>
                                
                                </div>
                            </div>
            </div>
        </div>
    )
}

export default Login;