import Marquee from "react-fast-marquee";
import { getPosts } from "../http";
import { useState, useEffect } from "react";
import {NavLink} from 'react-router-dom';

import { createCallback } from "../http";
import { validateMobileNumber } from "../tools/validator";


const Home = () => {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
               const {data} = await getPosts(1);
               setPosts(data.posts)
            } catch (error) {
                console.log(error)
            }
        }
        fetchPosts();
    }, [])

    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [service, setService] = useState('');
    const [location, setLocation] = useState('');
    const [error, setError] = useState({field: null, message: null});
    const [success, setSuccess] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async () => {
        setError({field: null, message: null});
        if(!name) return setError({field: 'name', message: 'Name is required'});
        if(!mobile) return setError({field: 'mobile', message: 'Mobile is required'});
        if(!validateMobileNumber(mobile)) return setError({field: 'mobile', message: 'Invalid mobile number'})
        if(!location) return setError({field: 'location', message: 'Location is required'});
        if(!service) return setError({field: 'service', message: 'Service is required'});

        setSubmitting(true)

        try {
            const {data} = await createCallback({name, mobile, service, location});
            console.log(data)
            if(data.success){
               setSuccess("Your request submittes successfully")
               setName('');
               setMobile('');
               setService('');
               setLocation('')
            }
        } catch (error) {
            console.log(error);
            setError({field: 'form', message: 'Request not sent'})
        } finally{
            setSubmitting(false)
        }
       
    }


    return (
        <div className="page-content">
            <section id="hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 d-flex align-items-center">
                            <div className="hero-content">
                                <h1 className="hero-heading text-white">Building Protection and Restoration Through Waterproofing, Repairs, and <span className="color-primary">Flooring Expertise.</span></h1>
                                <p className="text text-light hero-des">From waterproofing new constructions to restoring old buildings, we ensure long-lasting protection and durability. Our expert team also offers repair services and premium tiling and epoxy flooring solutions to enhance your spaces</p>
                                <div className="hero-conuters row">
                                    <div className="hero-counter-item col-4">
                                        <h1 className="counter-number text-white">12</h1>
                                        <p className="conter-text text-light">Years Experience</p>
                                    </div>
                                    <div className="hero-counter-item col-4">
                                        <h1 className="counter-number text-white">40</h1>
                                        <p className="conter-text text-light">Expertise Team</p>
                                    </div>
                                    <div className="hero-counter-item col-4">
                                        <h1 className="counter-number text-white">5</h1>
                                        <p className="conter-text text-light">States Serving</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 d-flex justify-content-end">
                            <div className="hero-form">
                                <h2 className="accent-heading"><span className="color-primary">Talk</span> To Our Expert</h2>
                                <div className="form">
                                    <div className="mb-3">
                                       <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control input" id="Name" placeholder="Name" />
                                       {error.field == 'name' && <p className="status error">{error.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                       <input type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control input" id="Name" placeholder="Phone" />
                                       {error.field == 'mobile' && <p className="status error">{error.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                       <select onChange={(e) => setService(e.target.value)} class="form-select input" aria-label="Default select example">
                                           <option selected={service === ''}>Service</option>
                                           <option value="Water Leakage Old Building" selected={service === 'Water Leakage Old Building'}>Water Leakage Old Building</option>
                                           <option value="Bulding Reapair" selected={service === 'Bulding Reapair'}>Bulding Reapair</option>
                                           <option value="New Building Water Protection" selected={service === 'New Building Water Protection'}>New Building Water Protection</option>
                                           <option value='Tiling and Epoxy Flooring Solutions' selected={service === 'Tiling and Epoxy Flooring Solutions'}>Tiling and Epoxy Flooring Solutions</option>
                                       </select>
                                       {error.field == 'service' && <p className="status error">{error.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                       <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} className="form-control input" id="Location" placeholder="Location" />
                                       {error.field == 'location' && <p className="status error">{error.message}</p>}
                                    </div>
                                    <div className="d-grid">
                                    <button onClick={handleSubmit} type="button" class="btn btn-primary-main">
                                        <span>Book A Free Consultancy</span>
                                        {submitting ? <span class="spinner-border spinner-border-sm ml-4" style={{marginLeft: '8px'}} role="status" aria-hidden="true"></span> : (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                           <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                        </svg>)}
                                    </button>
                                    {error.field == 'form' && <p className="status error">{error.message}</p>}
                                    <p className="status success">{success}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <img src="./assets/images/about_1_1.png" alt="about us" />
                        </div>
                        <div className="col-7 d-flex align-items-center about-right">
                            <div>
                            <div className="about-content">
                                <h1 className="primary-heading text-white">Abot Home BOQ</h1>
                                <p className="text text-light">Home is a dream of every person, building a home is a lifetime achievement for many people at the same time protecting the home for long periods of time also an important thing.</p>
                                <div className="text text-light" style={{marginTop: '8px'}}>At HomeBoq.com we will provide you all the necessary consultancy for protecting your home for long periods of time.</div>
                            </div>
                            <div className="about-feature row gx-2 gy-2">
                                <div className="col-6">
                                    <NavLink to='/services/waterproofing-underconstruction-building'><div className="about-feature-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                        </svg>
                                        <span>Waterproofing Underconstruction...</span>
                                    </div></NavLink>
                                </div>
                                <div className="col-6">
                                    <NavLink to='/services/waterproofing-old-building'><div className="about-feature-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                        </svg>
                                        <span>Waterproofing Old Building</span>
                                    </div></NavLink>
                                </div>
                                <div className="col-6">
                                    <NavLink to='/services/repair-of-the-old-building'><div className="about-feature-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                        </svg>
                                        <span>Repair of the Old Building</span>
                                    </div></NavLink>
                                </div>
                                <div className="col-6">
                                    <NavLink to='/services/tiling-and-epoxy-flooring-solutions'><div className="about-feature-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                        </svg>
                                        <span>Tiling and Epoxy Flooring Solutions</span>
                                    </div></NavLink>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="solution">
                <div className="container">
                    <div className="sectin-heading-group">
                        <h1 className="primary-heading">Our <span className="color-primary">Solutions</span></h1>
                        {/* <button type="button" class="btn  btn-outline-primary">
                            <span>View All Details</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                        </button> */}
                    </div>
                    <div className="row solution gx-5 gy-5">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="./assets/images/solution1.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Waterproofing Underconstruction Building</h2>
                                    <p className="text"> Protect your new building from water damage with our advanced waterproofing solutions.</p>
                                    <NavLink to='/services/waterproofing-underconstruction-building'><button className="btn btn-primary">Details</button></NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="./assets/images/solution2.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Waterproofing Old Building</h2>
                                    <p className="text">Enhance the durability of older structures with expert waterproofing designed to prevent leaks and moisture damage.</p>
                                    <NavLink to='/services/waterproofing-old-building'><button className="btn btn-primary">Details</button></NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="./assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Repair of the Old Building</h2>
                                    <p className="text">Restore the structural integrity and aesthetic appeal of aging buildings through comprehensive repair services.</p>
                                    <NavLink to='/services/repair-of-the-old-building'><button className="btn btn-primary">Details</button></NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="solution-item">
                                <div className="solution-image">
                                    <img src="./assets/images/solution3.png" alt="solution" />
                                </div>
                                <div className="solution-content">
                                    <h2 className="secondary-heading">Tiling and Epoxy Flooring Solutions</h2>
                                    <p className="text">Upgrade your space with durable, stylish tiling and epoxy flooring that offer both function and beauty.</p>
                                    <NavLink to='/services/tiling-and-epoxy-flooring-solutions'><button className="btn btn-primary">Details</button></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="howitwork">
                <div className="container">
                    <div className="sectin-heading-group">
                        <h1 className="primary-heading">How it <span className="color-primary">Works ?</span></h1>
                        {/* <button type="button" class="btn  btn-outline-primary">
                            <span>View All Details</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                        </button> */}
                    </div>
                    <div className="section-intro">
                        <p className="text">
                        Here’s how the service request process works at Home BOQ for roof waterproofing, bathroom waterproofing, internal walls waterproofing, and external waterproofing:
                        </p>
                    </div>
                    <div className="row solution gx-5 gy-5">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="how-item">
                                <div className="how-image">
                                    <img src="./assets/images/how1.png" alt="how it work 1" />
                                </div>
                                <div className="how-content">
                                    <p className="text"><strong>1. Select The Service</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="how-item">
                                <div className="how-image">
                                    <img src="./assets/images/how2.png" alt="how it work 1" />
                                </div>
                                <div className="how-content">
                                    <p className="text"><strong>2. Fill The Form</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="how-item">
                                <div className="how-image">
                                    <img src="./assets/images/how3.png" alt="how it work 1" />
                                </div>
                                <div className="how-content">
                                    <p className="text"><strong>3. Our Executive will Reach you in 1Hr</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="how-item">
                                <div className="how-image">
                                    <img src="./assets/images/how4.png" alt="how it work 1" />
                                </div>
                                <div className="how-content">
                                    <p className="text"><strong>4. Get The Solution</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="review">
                <div className="container">
                   <div className="sectin-heading-group">
                        <h1 className="primary-heading">What our <span className="color-primary">client are saying</span></h1>
                        {/* <button type="button" class="btn  btn-outline-primary">
                            <span>View All</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                        </button> */}
                    </div>
                </div>
                <div className="container-fluid reviews">
                    <div className="row gx-5 gy-5">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="review-item">
                                <div className="review-header">
                                    <div className="row">
                                        <div className="col-3">
                                            <img className="rev-avatar" src="./assets/images/user1.png" alt="user" />
                                        </div>
                                        <div className="col-8 d-flex flex-column justify-content-center">
                                            <h3 className="review-heading">Suresh M.</h3>
                                            <p className="review-designation">Homeowne</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-body">
                                    <p className="text">"We had constant leaks every rainy season, but after using this roof waterproofing service, our home has remained dry and protected. The team was professional and efficient. Highly recommended!"</p>
                                </div>
                                <div className="review-footer">
                                    <ul>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="review-item">
                                    
<svg className="rev-icon" width="70" height="50" viewBox="0 0 102 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 77.7312V55.4911C0 49.0953 1.19922 42.4451 3.59766 35.5405C6.06877 28.5632 9.41205 21.9857 13.6275 15.8079C17.9156 9.55742 22.7125 4.28812 28.0181 0L46.3335 11.8832C42.0453 18.3517 38.4477 25.1109 35.5405 32.1609C32.706 39.1381 31.325 46.8422 31.3977 55.2731V77.7312H0ZM55.2731 77.7312V55.4911C55.2731 49.0953 56.4723 42.4451 58.8707 35.5405C61.3419 28.5632 64.6851 21.9857 68.9006 15.8079C73.1887 9.55742 77.9856 4.28812 83.2912 0L101.607 11.8832C97.3184 18.3517 93.7208 25.1109 90.8136 32.1609C87.979 39.1381 86.5981 46.8422 86.6708 55.2731V77.7312H55.2731Z" fill="#0888FF"/>
</svg>

                                
                                <div className="review-header">
                                    <div className="row">
                                        <div className="col-3">
                                            <img className="rev-avatar" src="./assets/images/user2.png" alt="user" />
                                        </div>
                                        <div className="col-8 col-8 d-flex flex-column justify-content-center">
                                            <h3 className="review-heading">Venkatesh L.</h3>
                                            <p className="review-designation">Renovation Specialist</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-body">
                                    <p className="text">"I had water seeping through the tiles in my bathroom, but their waterproofing solution fixed the issue completely. It’s been months, and the bathroom is still in perfect condition."</p>
                                </div>
                                <div className="review-footer">
                                    <ul>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="review-item">
                                <div className="review-header">
                                    <div className="row">
                                        <div className="col-3">
                                            <img className="rev-avatar" src="./assets/images/user1.png" alt="user" />
                                        </div>
                                        <div className="col-8 col-8 d-flex flex-column justify-content-center">
                                            <h3 className="review-heading">Priya R.</h3>
                                            <p className="review-designation">Property Manager</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="review-body">
                                    <p className="text">"The dampness on our internal walls was a persistent problem, especially during the monsoons. After this company’s internal wall waterproofing service, our walls have remained dry and mold-free."</p>
                                </div>
                                <div className="review-footer">
                                    <ul>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                        <li>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                               <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="blog">
                <div className="container">
                    <div className="sectin-heading-group">
                        <h1 className="primary-heading">Our <span className="color-primary">Blog</span></h1>
                        <NavLink to='/posts'><button type="button" class="btn  btn-outline-primary">
                            <span>View All Details</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                        </button></NavLink>
                    </div>
                    <div className="section-intro">
                        <p className="text">
                        Here is our blog content, offering expert insights, practical tips, and detailed solutions for maintaining structural integrity and enhancing the durability of your property.
                        </p>
                    </div>
                    <div className="row blog gx-5 gy-5">
                        

                        {
                            posts.map((post, i) => i < 3 ?(
                                <div className="col-lg-4 col-md-6 col-sm-12">
                            <NavLink to={`/posts/${post.slug}`}><div className="blog-item">
                                <div className="blog-image">
                                    <img src={post.image} alt="how it work 1" />
                                </div>
                                <div className="blog-content">
                                   <h2 className="secondary-heading">{post.title}</h2>
                                   <p className="text">{post.short_des}</p>
                                </div>
                            </div></NavLink>
                        </div>
                            ) : null)
                        }
                       
            
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home;