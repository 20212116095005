import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <div>
            <div className="footer">
                <div className="footer-cta">
                    <div className="cta-content">
                        <h1 className="footer-heading">Lets Built Your Dream Quicker</h1>
                        <p className="text text-light">"Seal your roof, protect your walls, and fortify your <br/>exterior—Book Now with HomeBOQ for expert Service.</p>
                        <div className="d-flex justify-content-center">
                            <NavLink to='/contact-us'><button type="button" class="btn  btn-outline-white">
                                <span>Contact Us</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                   <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </button></NavLink>
                            <NavLink to='/book-now'><button type="button" class="btn btn-primary hover-white">
                                <span>Book Now</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                   <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </button></NavLink>
                        </div>
                        <div style={{position: 'absolute', bottom: '-10px', left: '50%', transform: 'translateX(-50%)'}}>
                             <p className="text text-light">Affiliated By <strong><a style={{color: '#fff'}} href="#" target="_blang">Sandhi Engineers Private Limited </a></strong></p>
                            </div>

                    </div>
                    
                </div>
                <div className="footer-top footer-strip">
                    
                </div>
                <div className="footer-bottom footer-strip">
                    <div className="footer-bottom-content">
                            
                        <div className="container d-flex align-items-end">

                        
                           
                            <div className="footer-bottom-row d-flex justify-cntent-between">
                                <div className="copyright-text">
                                    <p className="text text-light">Copyright &copy; 2024 All Right Reserved By Home BOQ</p>
                                </div>
                                <div className="footer-link">
                                    <ul>
                                        <li className="text-light footer-link"><NavLink to='/terms-and-conditions' style={{color:'#fff'}}>Terms & Conditions</NavLink></li>
                                        <li className="text-light footer-link"><NavLink to='/privacy-policy'  style={{color:'#fff'}}>Privacy Policy</NavLink></li>
                                        <li className="text-light footer-link"><NavLink to='/refund-policy'  style={{color:'#fff'}}>Refund Policy</NavLink></li>
                                    </ul>
                                </div>
                                <div className="footer-branding">
                                    <p className="text text-light">Developed By <a style={{textDecoration: 'none'}} className="text-light" href="https://www.waytowebs.com" target="_blank">Waytowebs</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;