import { useState, useEffect } from "react";
import { getBookings } from "../http";
import ImageGallery from "react-image-gallery";
import { NavLink } from "react-router-dom";

const MyBooking = () => {

    const [bookings, setBookings] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();


    useEffect(() => {
        const fetchbooking = async () => {
            try {
               const {data} = await getBookings(page);
               setBookings(data.bookings)
               setTotal(data.total)
            } catch (error) {
                console.log(error)
            }
        }
        fetchbooking();
    }, [page])


    return (
        <div className="page-content">
             <section id="page-header" className="d-flex align-items-center">
          <div className="container">
              <div className="page-title">
                  <h1 className="page-heading text-white">
                      My Booking
                  </h1>
              </div>
          </div>
      </section>


      <section id="blog">
                <div className="container">
                    <div className="sectin-heading-group">
                        <h1 className="primary-heading">My <span className="color-primary">Booking</span></h1>
                        
                    </div>
                    <div className="section-intro">

                    </div>
                    <div className="row blog gx-5 gy-5">
                        

                        {
                            bookings.map((post) => (
                                <div className="col-4">
                            <div className="blog-item booking-it">
                                <div className="blog-image">
                                <ImageGallery originalHeight={200} items={post.images.map((im) => ({ original: im, thumbnail: im }))} />
                                </div>
                                <div className="blog-content">
                                   <div className="booking-row">
                                     <p className="text"><strong>Booking Type :</strong></p>
                                     <p className="text">{post.booking_type}</p>
                                   </div>
                                   <div className="booking-row">
                                     <p className="text"><strong>Service :</strong></p>
                                     <p className="text">{post.service}</p>
                                   </div>
                                   <div className="booking-row">
                                     <p className="text"><strong>Location :</strong></p>
                                     <p className="text">{post.location}</p>
                                   </div>
                                   <div className="booking-row">
                                     <p className="text"><strong>Amount Paid :</strong></p>
                                     <p className="text">{post.amount_paid ? `₹: ${post.amount_paid}` : 'Not Applicable'}</p>
                                   </div>
                                   <div className="booking-row">
                                     <p className="text"><strong>Transection ID :</strong></p>
                                     <p className="text">{post.transection_id ? `₹: ${post.transection_id}` : 'Not Applicable'}</p>
                                   </div>
                                </div>
                            </div>
                        </div>
                            ))
                        }

                        {
                            bookings.length == 0 && <div className="booing-not-found">
                                <p className="text" style={{opacity: '.7', marginBottom: '25px'}}><strong>Bookin not found.</strong></p>
                                <NavLink to='/book-now'><button className="btn btn-primary" type="submit">
        <span>Book Now</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
</svg>

    </button></NavLink>
                            </div>
                        }
                       
            
                    </div>
                </div>
                <div style={{paddingTop: '30ps', display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                {bookings.length > 6 && <nav aria-label="Page navigation example">
  <ul className="pagination">
    <li className={`page-item ${page == 1 ? 'disabled': ''}`} onClick={() => page > 1 ? setPage(page => page - 1) : null}>
      <a className="page-link" href="#" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li className="page-item"><span className="page-link">{page} / {Math.ceil(total / 6)}</span></li>
    <li className={`page-item ${page == Math.ceil(total / 6) ? 'disabled': ''}`} onClick={() => page < Math.ceil(total / 6) ? setPage(page => page + 1) : null}>
      <a className="page-link" href="#" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>}
                </div>
            </section>
        </div>
    )
}

export default MyBooking;