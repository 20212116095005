import { useState, useEffect } from "react";
import { getPosts } from "../http";
import {NavLink} from 'react-router-dom';

const Posts = () => {

    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState('')

    useEffect(() => {
        const fetchPosts = async () => {
            try {
               const {data} = await getPosts(page);
               setPosts(data.posts)
               setTotal(data.total)
            } catch (error) {
                console.log(error)
            }
        }
        fetchPosts();
    }, [page, search])

    return(
        <div className="page-content">
        <section id="page-header" className="d-flex align-items-center">
          <div className="container">
              <div className="page-title">
                  <h1 className="page-heading text-white">
                      Posts
                  </h1>
              </div>
          </div>
      </section>

      <section id="blog">
                <div className="container">
                    <div className="sectin-heading-group">
                        <h1 className="primary-heading">Our <span className="color-primary">Blog</span></h1>
                        <button type="button" class="btn  btn-outline-primary">
                            <span>View All Details</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                        </button>
                    </div>
                    <div className="section-intro">
                        {/* <p className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                        </p> */}
                    </div>
                    <div className="row blog gx-5 gy-5">
                        

                        {
                            posts.map((post) => (
                                <div className="col-4">
                            <NavLink to={`/posts/${post.slug}`}><div className="blog-item">
                                <div className="blog-image">
                                    <img src={post.image} alt="how it work 1" />
                                </div>
                                <div className="blog-content">
                                   <h2 className="secondary-heading">{post.title}</h2>
                                   <p className="text">{post.short_des}</p>
                                </div>
                            </div></NavLink>
                        </div>
                            ))
                        }
                       
            
                    </div>
                </div>
                <div style={{paddingTop: '30ps', display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                <nav aria-label="Page navigation example">
  <ul className="pagination">
    <li className={`page-item ${page == 1 ? 'disabled': ''}`} onClick={() => page > 1 ? setPage(page => page - 1) : null}>
      <a className="page-link" href="#" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li className="page-item"><span className="page-link">{page} / {Math.ceil(total / 6)}</span></li>
    <li className={`page-item ${page == Math.ceil(total / 6) ? 'disabled': ''}`} onClick={() => page < Math.ceil(total / 6) ? setPage(page => page + 1) : null}>
      <a className="page-link" href="#" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>
                </div>
            </section>
      </div>
    )
}

export default Posts;