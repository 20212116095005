

import { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { verifyPaymentReq } from "../http";

const Veryfypayments = () => {

    const navigate = useNavigate();

    const {tid} = useParams();

    let [searchParams, setSearchParams] = useSearchParams();
    const [body, setBody] = useState(searchParams.get('body'));

    useEffect(() => {
        //console.log('tid', tid);
        //console.log('body', JSON.parse(body))
        const fetchBooking = async () => {
            try {
               const {data} = await verifyPaymentReq(tid, JSON.parse(body));
               if(data.success){
                navigate(`/booking/success/${data.booking._id}`)
               }else{
                alert('Unable to veryfy payments')
                //navigate('/booknow')
               }

               console.log(data)
             
            } catch (error) {
                console.log(error)
            }
        }
        fetchBooking();
    }, [tid])


    return (
        <div style={{width: '100%', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h2 class="secondary-heading" style={{textAlign: "center"}}>Please wait<br/>we are verifying payments...</h2>
        </div>
    )
}

export default Veryfypayments;