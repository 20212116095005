import { useState } from "react";
import {createBooking} from '../http';
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { NavLink, useNavigate } from "react-router-dom";

const Booknow = () => {

  const [image, setImage] = useState([]);


  const [service, setService] = useState();
  const [location, setLocation] = useState();
  const [booking_Type, setBookingType] = useState('Request A Callback');
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState({field: null, message: null})
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async () => {
    setError({field: null, message: null});

    if(!service){
      setError({field: 'service', message: 'Service is required'});
      return
    }
    if(!location){
      setError({field: 'location', message: 'Location is required'});
      return
    }
    if(image.length == 0){
      setError({field: 'image', message: "Select at least one image"});
      return
    }
      try {
        const {data} = await createBooking({service, location, booking_type: booking_Type, image});
        if(data.payment_initiated){
          window.location = data.data.instrumentResponse.redirectInfo.url
          console.log(data);
          return
        }
        navigate(`/booking/success/${data.booking._id}`)
      } catch (error) {
        setError({field: 'location', message: error.response.data.message})
      }finally{
        setService(false)
      }
  }


  const handleRemoveImage = (index) => {
    let img = [...image];

    img.splice(index, 1);
    setImage(img)
  }

  const handleFile = (e) => {

   

    const uploadedFiles = Array.from(e.target.files);
    // console.log(Array.from(uploadedFiles))
    let img = []

    uploadedFiles.forEach((file) => {
      const reader = new FileReader();
       reader.readAsDataURL(file);
       reader.onload = function(){
        console.log(reader.result)
        img = [...img, reader.result]
        setImage(img)
       }
    })

    

  }
    return (
        <div className="page-content">
        <section id="page-header" className="d-flex align-items-center">
          <div className="container">
              <div className="page-title">
                  <h1 className="page-heading text-white">
                      Book Now
                  </h1>
              </div>
          </div>
      </section>

      <section id="booknow">
        <div className="container">
            <div className="sec-heading">
                <p className="text color-primary">Book Now</p>
                <h1 className="primary-heading">High Quality Construction Solutions <br/> For Residentials & Industries!</h1>
            </div>
            {auth.name && <div className="booking-wrapper">
            <div className="booking-body">
            <div className="row gx-5 gy-3">
            <div className="col-lg-8 col-md-6 col-sm-12">
                <div className="image-preview">
                    <p className="text"><strong>{image.length} image selected</strong></p>
                    {error.field == 'image' && <p className="status error">{error.message}</p>}
                    <div className="preview-box">
                      {
                        image.map((img, i) => (
                        <div className="prwview-item" style={{position: 'relative'}}>
                          <img src={img} alt="thumbnail" />
                          <button onClick={() => handleRemoveImage(i)} style={{position: 'absolute', right: '5px', top: '-5px', width: '30px', height: '30px', background: '#0888FF', color: '#fff', border: 'none', outline: 'none', borderRadius: '50%', fontSize: '18px'}}>-</button>
                        </div>
                      ) )
                    }
                      
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="bookforn">
                        <div className="contact-form-inner">
                                <div className="form">
                                    <div className="mb-3">
                                       <select onChange={(e) => setService(e.target.value)} class="form-select input" aria-label="Default select example">
                                           <option selected>Service</option>
                                           <option value="Water Leakage Old Building">Water Leakage Old Building</option>
                                           <option value="Bulding Reapir">Bulding Reapir</option>
                                           <option value="New Building Water Protection">New Building Water Protection</option>
                                           <option value='Tiling and Epoxy Flooring Solutions'>Tiling and Epoxy Flooring Solutions</option>
                                       </select>
                                       {error.field == 'service' && <p className="status error">{error.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                       <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} className="form-control input" id="email" placeholder="Location" />
                                       {error.field == 'location' && <p className="status error">{error.message}</p>}
                                    </div>
                                    
                                  
                                    <div class="form-check mb-3">
  <input class="form-check-input" onChange={(e) => setBookingType(e.target.value)} value="Pay Online" type="radio" name="booking_type" id="flexRadioDefault1" checked={booking_Type === 'Pay Online'} />
  <label class="form-check-label" for="flexRadioDefault1">
    Pay Online (Payable amount is ₹: 500 /-)
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" onChange={(e) => setBookingType(e.target.value)} value="Request A Callback" type="radio" name="booking_type" id="flexRadioDefault2" checked={booking_Type === 'Request A Callback'} />
  <label class="form-check-label" for="flexRadioDefault2">
    Request A Callback.
  </label>
</div>
                                </div>
                            </div>
                        </div>
            </div>
        </div>
            </div>
              
              <div className="booking-footer">
                <div>
                   <label for="formFile" class="form-label">
                       Select Photos.
                   </label>
                   <input class="form-control" onChange={(e) => handleFile(e)} type="file" id="formFile" multiple/>
                </div>
                <button type="button" class="btn btn-primary-main" onClick={handleSubmit}>
                <span>Submitting</span>
                                        {submitting ? <span class="spinner-border spinner-border-sm ml-4" style={{marginLeft: '8px'}} role="status" aria-hidden="true"></span> : (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                           <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                        </svg>)}
                </button>
              </div>
            </div>}
            {
              !auth.name &&                                 <div style={{width:'250px', margin: '0 auto'}}><NavLink style={{display:'grid', width: '100%', marginTop: '10px'}} to='/login'><button className="btn btn-primary" type="submit">
              <span>Login to Continue</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
      </svg>
      
          </button></NavLink></div>
            }
        </div>
      </section>
        </div>
    )
}

export default Booknow;