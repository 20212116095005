

const Refund = () => {
    return (
        <div className="page-content">
              <section id="page-header" className="d-flex align-items-center">
                <div className="container">
                    <div className="page-title">
                        <h1 className="page-heading text-white">
                           Refund and Cancellation policy 
                        </h1>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                   <h1 className="primary-heading">Refund and Cancellation policy </h1>
                   <p className="text"> This refund and cancellation policy outlines how you can cancel or seek a refund for a product / service  that you have purchased through the Platform. Under this policy:</p>
                   <div className="policy-section">
                    <ol>
                        <li className="text">Cancellations will only be considered if the request is made 7 working days of placing the order.  However, cancellation requests may not be entertained if the orders have been communicated to  such sellers / merchant(s) listed on the Platform and they have initiated the process of shipping  them, or the product is out for delivery. In such an event, you may choose to reject the product at  the doorstep. </li>
                        <li className="text">Sandhi Engineers Private Limited (Home BOQ) does not accept cancellation requests for perishable items like flowers,  eatables, etc. However, the refund / replacement can be made if the user establishes that the quality  of the product delivered is not good. </li>
                        <li className="text">In case of receipt of damaged or defective items, please report to our customer service team. The  request would be entertained once the seller/ merchant listed on the Platform, has checked and  determined the same at its own end. This should be reported within the 7 working days of receipt of products.  In case you feel that the product received is not as shown on the site or as per your expectations,  you must bring it to the notice of our customer service within a 7 working days of receiving the product.  The customer service team after looking into your complaint will take an appropriate decision. </li>
                        <li className="text">In case of complaints regarding the products that come with a warranty from the manufacturers,  please refer the issue to them. </li>
                        <li className="text">In case of any refunds approved by Sandhi Engineers Private Limited (Home BOQ), it will take a 7 working days for the refund  to be processed to you.</li>
                    </ol>
                    <h2 className="secondary-heading">Return Policy</h2>
                    <p className="text">We offer refund / exchange within first 7 working days from the date of your purchase. If 7 working days have passed  since your purchase, you will not be offered a return, exchange or refund of any kind. In order to become  eligible for a return or an exchange, (i) the purchased item should be unused and in the same condition as  you received it, (ii) the item must have original packaging, (iii) if the item that you purchased on a sale,  then the item may not be eligible for a return / exchange. Further, only such items are replaced by us  (based on an exchange request), if such items are found defective or damaged. </p>
                    <p className="text">You agree that there may be a certain category of products / items that are exempted from returns or  refunds. Such categories of the products would be identified to you at the item of purchase. For exchange  / return accepted request(s) (as applicable), once your returned product / item is received and inspected  by us, we will send you an email to notify you about receipt of the returned / exchanged product. Further.  If the same has been approved after the quality check at our end, your request (i.e. return / exchange) will  be processed in accordance with our policies.</p>
                    {/* <h2 className="secondary-heading">Shipping Policy</h2>
                    <p className="text">The orders for the user are shipped through registered domestic courier companies and/or speed post  only. Orders are shipped within 7 working days from the date of the order and/or payment or as per the delivery  date agreed at the time of order confirmation and delivering of the shipment, subject to courier company /  post office norms. Platform Owner shall not be liable for any delay in delivery by the courier company /  postal authority. Delivery of all orders will be made to the address provided by the buyer at the time of  purchase. Delivery of our services will be confirmed on your email ID as specified at the time of  registration. If there are any shipping cost(s) levied by the seller or the Platform Owner (as the case be),  the same is not refundable.</p> */}
                   </div>
                </div>
            </section>
        </div>
    )
}

export default Refund;